.potexticon {
  font-size: 0.875rem !important;
  margin-right: 3px;
  color: #333;
}

.potextico {
  font-size: 1rem !important;
  margin-right: 3px;
}


.potexticonfolder {
  margin-right: 5px;
  color: #ffb74d;
}

.potexticonxl {
  margin-right: 5px;
  color: #ab47bc;
}

.btniconsmall {
  font-size: 16px !important;
  margin-right: 5px;
}

.headermenuicon {
  margin-right: 10px;
}

.pobtnicon {
  font-size: 0.875rem !important;
  margin-right: 5px;
}

.potagbox {
  margin-right: 5px;
  padding: 2px 8px 2px 8px;
  font-size: 12px;
  border: 1px solid #333;
  border-radius: 10px 10px 10px 10px;
}

.pobody1icon {
  font-size: 18px !important;
  margin-right: 5px;
}

.text-success {
  color: #388e3c !important;
}

.text-warning {
  color: #f57c00 !important;
}

.text-danger {
  color: #d32f2f !important;
}

.potypeicon {
  align-items: center;
  display: flex;
}

.potopmenu {
  color: #aaa;
  text-decoration: none !important;
}

.potopmenu:hover {
  color: #ddd;
  text-decoration: none !important;
}

.poteambox {
  font-size: 1rem !important;
  margin-right: 5px;
  color: #26A69A;
  padding: 2px 5px 2px 5px;
  border: 1px solid #333;
  border-radius: 10px 10px 10px 10px;
}

.poteamboxowner {
  font-weight: bold !important;
}

.img-fluid {
  max-width: 100% !important;
  max-height: 100% !important;
}

.CardLog {
  padding: 3px 10px 0px 10px;
  border: 1px solid transparent !important;
}

.CardLog:hover {
  border: 1px solid #333 !important;
}

.logicon {
  color: #00796b !important;
  margin-right: 10px;
}

.logiconlink {
  color: #2196f3 !important;
  margin-right: 10px;
}

.loga {
  color: #ff3d00 !important;
}

.logb {
  color: #ffff00 !important;
}

.loginfo {
  color: #2196f3 !important;
}

.logfun {
  color: #7e57c2 !important;
}

.logextras {
  font-size: 16px !important;
  align-items: center;
  display: flex;
}

.logextrasicon {
  font-size: 18px !important;
  align-items: center;
  display: flex;
}

.logareabox {
  color: #bcaaa4;
  font-size: 12px !important;
  border: 1px solid #333;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  margin-right: 5px;
  align-items: center;
  display: flex;
}

.logextrasticon {
  margin-left: 5px;
  font-size: 14px !important;
  align-items: center;
  display: flex;
}

.logextrasticon:hover {
  transform: scale(1.5);
}

.pochip {
  color: #aaa !important;
  padding: 2px 5px 2px 5px;
  border-radius: 15px;
  border: 1px solid transparent;
  display: inline-block;
  margin-right: 5px;
  align-items: center;
  display: flex;
}

.pochip:hover {
  color: #fff !important;
  /*     border: 1px solid #333; */
}

.pochipimg {
  border-radius: 100px !important;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.pochipoption {
  position: absolute !important;
  right: 50px;
}

.pochipoptiondisabled {
  position: absolute !important;
  right: 50px;
  color: #333 !important;
}

.logtimebox {
  font-size: 14px !important;
  padding: 0px 5px 0px 5px;
  border: 1px solid #333;
  border-radius: 5px;
  margin-right: 10px;
}

.CardHeader {
  padding: 5px 0px 5px 5px;
  color: #aaa !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.CardHeaderText {
  font-size: 16px !important;
  padding-left: 5px;
  margin-top: 20px;
}

.pocardheaderoption:hover {
  color: #2196f3 !important;
}

.pocardheaderoptiondisabled {
  color: #333 !important;
}

.CardLogPublic {
  border: 1px solid #ff3d00 !important;
}

.CardLogPublic:hover {
  border: 1px solid #ff3d00 !important;
}

.CardMod {
  border: 1px solid #ff3d00 !important;
}

.CardMod:hover {
  border: 1px solid #ff3d00 !important;
}

.infobox {
  font-size: 16px !important;
  padding: 0px 5px 0px 5px;
  border: 1px solid #333;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 5px;
}


.CardPublic {
  border: 1px solid #7e57c2 !important;
}

.CardPublic:hover {
  border: 1px solid #ab47bc !important;
}

.cardlink {
  padding: 5px 10px 5px 10px;
  background-color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: rgb(0, 0, 0, 0.5) 0px 0px 2px 2px inset;
}

.cardlink:hover {
  /* box-shadow: rgba(0, 0, 0, 1) 1px 1px 5px 5px inset; */
  /* box-shadow: rgb(66, 165, 245, 0.4) 0px 0px 2px 2px inset; */
  box-shadow: rgb(66, 165, 245, 0.8) 0px 0px 1px 1px inset;
  /* border: 1px solid transparent; */
  /* border: 1px solid rgb(21, 101, 192, 0.8) !important; */
}


.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  overflow: none;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all .15s linear;
  border: 1px solid #888;
  border-radius: 4px;
}

.dzu-dropzoneActive {
  background-color: #777;
  border-color: #2484FF;
}

.dzu-dropzoneDisabled {
  opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #2484FF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 0px;
  font-weight: 600;
  color: #2484FF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-previewContainer {
  padding: 40px 3%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ECECEC;
  box-sizing: border-box;
}

.dzu-previewStatusContainer {
  display: none;
  align-items: center;
}

.dzu-previewFileName {
  display: none;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.dzu-previewImage {
  width: auto;
  max-height: 40px;
  max-width: 140px;
  border-radius: 4px;
}

.dzu-previewButton {
  display: none;
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}

.dzu-submitButtonContainer {
  display: none;
  margin: 24px 0;
  z-index: 1;
}

.dzu-submitButton {
  display: none;
  padding: 0 14px;
  min-height: 32px;
  background-color: #2484FF;
  border: none;
  border-radius: 4px;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-submitButton:disabled {
  display: none;
  background-color: #E6E6E6;
  color: #333333;
  cursor: unset;
}

.tvepisodelist {
  font-size: 12px;
}


.UploadFiles__root {
  padding: 2px;
  width: 100%;
  /* border: 1px solid #ddd; */
}

.UploadFiles__dropzone {
  margin-bottom: 16px;
  padding: 20px;
  border: 1px dotted #ddd;
}

.FileItem__root {
  margin: 4px 0;
  padding: 8px;
}

.FileItem__name {
  display: block;
}

.FileItem__progress {
  margin-right: 8px;
}

.FileItem__error {
  color: red;
}

.pocard {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
}

.cardnew {
  box-shadow: rgba(255, 255, 0, 1) 3px 0px 0px 0px inset;
}

.carddanger {
  border: 1px solid rgba(172, 0, 0, 0.87);
}

.cardok {
  border: 1px solid green;
}

.droponme {
  border: 1px solid green;
}

.tvseriesbox {
  border: 1px solid transparent;
  /* min-height: 150px; */
}

.tvseriesbox:hover {
  border: 1px solid #fff;
}

.dragging {
  opacity: .5;
  transform: scale(.8);
}

.draggingbox {
  transform: scale(1.2);
  border: 1px solid #fff;
}

.draggingover {
  border: 1px dotted #ddd;
}

.navlink {
  color: #ddd;
  text-decoration: none !important;
}

.loginbglight {
  position: absolute;
  width: 0px;
  opacity: .75;
  background-color: #000;
  /* box-shadow: #0d8585 0px 0px 20px 2px; */
  box-shadow: #7e57c2 0px 0px 10px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.loginx1 {
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-transform: scale(1.0);
  -moz-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform: scale(1.0);
}

.loginx2 {
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.loginx3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
}

.loginx4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.loginx5 {
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.loginx6 {
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
}

.loginx7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.loginx8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.loginx9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-moz-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-o-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

.bg {
  background-image: radial-gradient(circle 815px at 23.4% -21.8%, rgba(9, 29, 85, 1) 0.2%, rgba(0, 0, 0, 1) 100.2%);
}

.pobox {
  /* border: 1px solid rgba(255, 255, 255, 0.12); */
  padding: 1px 1px 1px 1px;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0, 0.5) 10px 10px 10px, rgba(255, 255, 255, 0.15) 1px 1px 0px 0px inset;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  width: 100%;
}

.poboxlogin {
  /* border: 1px solid rgba(255, 255, 255, 0.12); */
  padding: 1px 1px 1px 1px;
  border-radius: 20px;
  box-shadow: rgb(0, 0, 0, 0.9) 10px 10px 10px, rgba(255, 255, 255, 0.25) 1px 1px 0px 0px inset;
  background-color: rgba(0, 0, 0, 0.95);
  margin-bottom: 10px;
  width: 100%;
}



.poeventname {
  color: #4DB6AC;
}

.pofiledetails {
  font-size: 12px;
  color: rgb(144, 164, 174) !important;
}

.potimeremaining {
  color: rgb(255, 202, 40, 0.6) !important;
}

.potextic {
  font-size: 0.875rem !important;
  margin-right: 3px;
  color: rgb(144, 164, 174) !important;
}

.potexticxl {
  font-size: 2.5rem !important;
  margin-right: 3px;
  color: rgb(144, 164, 174) !important;
}

.potexticred {
  font-size: 0.875rem !important;
  margin-right: 3px;
  color: #c62828 !important;
}

.potextteam {
  color: #00bcd4 !important;
}

.potextlocation {
  color: #9e9d24 !important;
}

.pochipjoin {
  background-color: rgb(0, 0, 0, 0.6) !important;
}

.pocomavatar {
  max-height: 25px !important;
  max-width: 25px !important;
}

.poiconex {
  max-height: 25px !important;
  max-width: 25px !important;
}

.poiconnew {
  color: yellow !important;
}

.posmalltext {
  font-size: 8px !important;
}

.poviewusers {
  font-size: 12px !important;
  font-style: italic !important;
  margin-right: 2px !important;
}

.potextmissing {
  color: #c62828 !important;
  font-size: 12px !important;
}

.pored {
  color: #c62828 !important;
}

.pogreen {
  color: #00796b !important;
}

.poyellow {
  color: #FFFF00 !important;
}

.pocolorinfo {
  color: #00cfbb !important;
}

.pocolorinfoborder {
  border-color: #00cfbb !important;
}

.itsfriday {
  z-index: 1000000 !important;
}

.cardarchlink {
  color: #ddd !important;
  padding: 15px 20px 15px 20px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0, 0.5) 0px 0px 2px 2px inset;
}

.cardarchlink:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}













@keyframes closeWindow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999 !important;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 9999999999999 !important; */
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}

.ril__navButtons:hover {
  opacity: 1;
}

.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==') no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+') no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-top: 48px;
  left: 100;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}

.ril__builtinButton:hover {
  opacity: 1;
}

.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}

.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=') no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+') no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=') no-repeat center;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {

  0%,
  19.999%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}

.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}

.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}

.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}

.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}

.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}

.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}

.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}

.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}

.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}

.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}

.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}

.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}

.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}

.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}

.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}

.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}

.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}


.loginfield input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  font-weight: bolder;
  font-size: 16px;
  color: green;
  background-color: rgb(0, 0, 0, 0.8);
}

.loginfield input[type="password"] {
  letter-spacing: 1px;
}

.loginfield input[type="number"] {
  letter-spacing: 5px;
}


.backdropbg {
  /* background-image: radial-gradient(circle 815px at 23.4% -21.8%, rgba(9, 29, 85, 1) 0.2%, rgba(0, 0, 0, 1) 100.2%) !important; */
  background-color: rgb(0, 0, 0, 0.9) !important;
}

.polightgreen {
  color: green !important;
}

.menustat {
  font-weight: 500 !important;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  border: 1px solid #333 !important;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px !important;
}

.menustatnew {
  font-weight: 500 !important;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  border: 1px solid #333 !important;
  color: rgba(255, 255, 0, 1);
  font-size: 12px !important;
}

.menustattimeout {
  font-weight: 500 !important;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  border: 1px solid #333 !important;
  color: rgba(255, 0, 0, 0.5);
  font-size: 12px !important;
}



.archivefilerow:hover {
  background-color: rgb(0, 0, 0, 0.2);
}

.powiped {
  text-decoration: line-through !important;
  color: red !important;
}

.poheadericon {
  font-size: 18px !important;
  margin-right: 5px;
  color: rgb(255, 255, 255, 0.5);
}

.poboxin {
  background-color: rgb(0, 0, 0, 0.1);
  box-shadow: inset 0 0 10px rgb(0, 0, 0, 1);
}

.ponewfile {
  color: rgba(255, 255, 0, 1) !important;
  font-weight: 900;
}

.text-muted {
  color: rgb(255, 255, 255, 0.5);
}

.archlogo {
  font-size: 16px !important;
}

.pomenuleft {
  background-color: #0D0D0D !important;
  min-height: 100% !important;
}

.paracard {
  padding: 10px;
  background-color: #0c1020 !important;
}

.pcard {
  border: 1px solid transparent;
  background-color: #0B0D0F !important;
}

.pcard-success {
  border: 1px solid #00796b !important;
}

.pcard-warning {
  border: 1px solid #f57c00 !important;
}

.pcard-danger {
  border: 1px solid #c62828 !important;
}

/* .paracard:hover {
  background-color: #070E13 !important;
} */


.pcard {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #F9F9F9 !important;
  background-color: #0D0D0D !important;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 10px;
}

.pcardheader {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  padding: 5px 0px 0px 10px;
  border-radius: 5px 5px 0px 0px;
  color: #F9F9F9 !important;
  background-color: #262626 !important;
  min-height: 30px;
}

.pcardbody {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 10px 10px 10px 10px;
  background-color: #0D0D0D !important;
  font-size: 14px;
  color: #8B8B8B !important;
  border-radius: 5px 5px 5px 5px;
}

.pcardoptions {
  padding: 2px 5px 0px 10px;
}

.pcardoptionicon {
  color: #4285F4;
  font-size: 18px !important;
}

.pcardoptionicon:hover {
  color: #fff;
}

.tvbox {
  border-radius: 5px 5px 5px;
  border: 1px solid transparent;
  min-height: 250px !important;
}

.tvbox:hover {
  scale: 1.1;
  border-radius: 5px 5px 5px;
  border: 1px solid transparent;
}

.scroll-container {
  overflow-x: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}